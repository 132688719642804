import { IApplicationClientConfiguration } from '@kurtosys/types/api/applicationManager/IApplicationClientConfiguration';

import { IAppConfigurationBase } from '../models/IAppConfigurationBase.js';

export const APPLICATION_CLIENT_CONFIGURATIONS: IApplicationClientConfiguration[] = [
	{
		applicationClientConfigurationId: 27,
		clientId: 24,
		name: 'AXA IM',
		applicationClientConfigurationTypeId: 1,
		description: 'AXA IM base config',
		configuration: {
			core: {
				embedding: {
					embeds: [
						{
							applicationCode: 'd2ba6f79-0553-44a4-a8ab-d133574afed3',
							embedId: 'ksys-app-page-header',
							templateId: 'ksys-app-page-header',
						},
						{
							applicationCode: '60bfdd54-f123-4a85-bda7-a72d371b1aa4',
							embedId: 'ksys-app-commentary',
							templateId: 'ksys-app-commentary',
						},
						{
							applicationCode: '0d3d3d29-bf28-42b4-a12a-02cfc4d6827d',
							embedId: 'ksys-app-fund-managers',
							templateId: 'ksys-app-fund-managers',
						},
						{
							applicationCode: '92559d10-9fea-4ac6-90fe-1ff9583718d6',
							embedId: 'ksys-app-key-facts',
							templateId: 'ksys-app-key-facts',
						},
						{
							applicationCode: '29c140af-c68b-4bf5-93da-f7b498b1ba24',
							embedId: 'ksys-app-document-list',
							templateId: 'ksys-app-document-list',
						},
						{
							applicationCode: '8335b53b-0a3e-4807-99a6-abf2d86ba45a',
							embedId: 'ksys-app-document-explorer',
							templateId: 'ksys-app-document-explorer',
						},
						{
							applicationCode: 'b4b3df37-bacd-4faa-bc3d-6ab40ff6f44c',
							embedId: 'ksys-app-fund-cards',
							templateId: 'ksys-app-fund-cards',
						},
						{
							applicationCode: '1d9cb15b-9f10-4c41-9884-e2227bcbeb79',
							embedId: 'ksys-app-line-chart',
							templateId: 'ksys-app-line-chart',
						},
						{
							applicationCode: 'd7469e40-79a3-40b8-9774-6648a5116c78',
							embedId: 'ksys-app-pie-chart',
							templateId: 'ksys-app-pie-chart',
						},
						{
							applicationCode: '13e828fc-397c-4b0a-b0d1-57f61f17ac28',
							embedId: 'ksys-app-radial-chart',
							templateId: 'ksys-app-radial-chart',
						},
						{
							applicationCode: '1cb00a79-7a8f-471c-93f0-a25adb73edbe',
							embedId: 'ksys-app-key-facts-list',
							templateId: 'ksys-app-key-facts-list',
						},
						{
							applicationCode: '293cb50b-0090-4f3b-b5c3-2b7ade0c6132',
							embedId: 'ksys-app-rating-scale',
							templateId: 'ksys-app-rating-scale',
						},
						{
							applicationCode: '332833c5-0234-48b8-aedf-ebe569aa8b60',
							embedId: 'ksys-app-global-inputs',
							templateId: 'ksys-app-global-inputs',
						},
						{
							applicationCode: '2a7138ab-7dcc-4412-a53a-9d62f58c8dc1',
							embedId: 'ksys-app-data-download',
							templateId: 'ksys-app-data-download',
						},
						{
							applicationCode: '36552444-8594-4ae7-b20e-f8267229892e',
							embedId: 'ksys-app-table',
							templateId: 'ksys-app-table',
						},
						{
							applicationCode: '27b91343-e6f5-4b82-82bb-8820b51cd5e7',
							embedId: 'ksys-app-table-test',
							templateId: 'ksys-app-table',
							version: 'v1.13.1',
						},
						{
							applicationCode: '309093e5-74cd-44b7-8378-7c3ab0671baf',
							embedId: 'ksys-app-bar-chart',
							templateId: 'ksys-app-bar-chart',
						},
						{
							applicationCode: '031d581e-3cfe-4223-ac29-ea2d3a7b6cbd',
							embedId: 'ksys-app-tabs',
							templateId: 'ksys-app-tabs',
						},
						{
							applicationCode: 'c23eaa19-00b3-4ef3-bdb6-e423b7365cba',
							embedId: 'ksys-app-finder',
							templateId: 'ksys-app-finder',
						},
					],
					hostname: 'https://api-eu.kurtosys.app',
				},
				globalQueryFormats: [
					{
						format: {
							options: {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
								numberThresholds: [
									{
										text: '',
										value: 1000000,
									},
								],
							},
							type: 'number',
						},
						key: 'number_millions',
					},
					{
						format: {
							options: {
								dateFormatString: 'DD/MM/YYYY',
							},
							type: 'date',
						},
						key: 'short_date',
					},
					{
						format: {
							options: {
								day: 'numeric',
								month: 'long',
								year: 'numeric',
							},
							type: 'date',
						},
						key: 'long_date',
					},
					{
						format: {
							options: {
								dateFormatString: 'YYYY',
							},
							type: 'date',
						},
						key: 'year_date',
					},
					{
						format: {
							options: {
								currency: 'GBP',
								currencyDisplay: 'symbol',
								style: 'currency',
							},
							type: 'currency',
						},
						key: 'currency',
					},
					{
						format: {
							options: {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
							},
							type: 'number',
						},
						key: 'number_2dp',
					},
					{
						format: {
							options: {
								maximumFractionDigits: 1,
								minimumFractionDigits: 1,
							},
							type: 'number',
						},
						key: 'number_1dp',
					},
					{
						format: {
							options: {
								maximumFractionDigits: 0,
								minimumFractionDigits: 0,
							},
							type: 'number',
						},
						key: 'number_0dp',
					},
					{
						format: {
							options: {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
								style: 'percent',
							},
							transforms: [
								{
									operator: 'divide',
									value: 100,
								},
							],
							type: 'percent',
						},
						key: 'percentage',
					},
				],
				inputs: {
					key: 'appInputs',
					queries: [
						{
							queryId: 'urlPattern',
							queryOptionsType: 'none',
							value: '/:country/:language/:audience:/:postType/:page/#:hash',
						},
						{
							options: {
								window: {
									key: 'location.origin',
								},
							},
							queryId: 'origin',
							queryOptionsType: 'window',
						},
						{
							options: {
								window: {
									key: 'location.pathname',
								},
							},
							queryId: 'pathname',
							queryOptionsType: 'window',
						},
						{
							options: {
								url: {
									code: 'param',
									key: 'country',
									urlPattern: {
										key: 'urlPattern',
										queryOptionsType: 'input',
									},
								},
							},
							queryId: 'country',
							queryOptionsType: 'url',
						},
						{
							options: {
								window: {
									key: 'location.hash',
								},
							},
							queryId: 'hash',
							queryOptionsType: 'window',
						},
						{
							options: {
								url: {
									code: 'queryString',
									key: 'clientCode',
								},
							},
							queryId: 'clientCode',
							queryOptionsType: 'url',
						},
						{
							options: {
								url: {
									code: 'queryString',
									key: 'country',
								},
							},
							queryId: 'country',
							queryOptionsType: 'url',
							transforms: [
								{
									caseType: 'lower',
									transformOptionsType: 'case',
								},
							],
						},
						{
							options: {
								url: {
									code: 'queryString',
									key: 'segmentation',
								},
							},
							queryId: 'segmentation',
							queryOptionsType: 'url',
						},
						{
							options: {
								window: {
									key: 'data_producttype',
								},
							},
							queryId: 'productType',
							queryOptionsType: 'window',
						},
						{
							code: 'fund_id',
							entityType: 'CLSS',
							queryId: 'fund_id',
							queryOptionsType: 'property',
						},
					],
				},
			},
			culture: {
				base: 'Default',
				default: 'en-GB',
			},
			data: {
				context: {
					entities: [
						{
							id: 'dropdown',
							inputs: [
								{
									key: 'fundUmbrellaName',
									query: {
										code: 'fund_umbrella_name',
										entityType: 'CLSS',
										queryOptionsType: 'property',
									},
								},
							],
							savedSearchEntity: {
								inputs: [
									{
										appInputKey: 'fundUmbrellaName',
										savedSearchInputKey: 'fundUmbrellaName',
									},
								],
								key: 'relatedClassSearch',
							},
						},
					],
					entityByType: {
						CLSS: {
							clientCodeProperty: 'isin',
							inputKey: 'clientCode',
							savedSearchEntity: {
								inputs: [
									{
										appInputKey: 'clientCode',
										savedSearchInputKey: 'isin',
									},
								],
								key: 'singleClassSearch',
							},
						},
					},
					globalCommentaries: [
						{
							global: true,
						},
					],
					globalDisclaimers: [
						{
							global: true,
						},
					],
					performanceCheck: {
						end: {
							code: 'performance_evolution',
							entityType: 'CLSS',
							format: {
								globalFormatKey: 'short_date',
							},
							index: -1,
							key: 'date',
							queryOptionsType: 'timeseries',
							sort: {
								direction: 'ASC',
								key: 'date',
								type: 'date',
							},
						},
						measure: {
							operator: '>',
							period: 'MONTHS',
							value: 12,
						},
						start: {
							code: 'launch_first_nav_date_shareclass',
							entityType: 'CLSS',
							format: {
								globalFormatKey: 'short_date',
							},
							queryOptionsType: 'property',
						},
					},
				},
			},
		} as IAppConfigurationBase,
		authentication: {
			serviceUrl: 'https://api-eu.kurtosys.app',
			token: '617793dd-644b-4f9b-85aa-df2a6789b61a',
		},
		parentId: null,
		lastModified: '2023-04-19T07:09:53.000Z',
		lastModifiedBy: 2202,
		created: '2022-10-20T07:56:16.000Z',
		createdBy: 2054,
	},
	{
		applicationClientConfigurationId: 33,
		clientId: 24,
		name: 'AXA IM v4',
		applicationClientConfigurationTypeId: 1,
		description: 'AXA IM v4',
		configuration: {
			core: {
				embedding: {
					embeds: [
						{
							applicationCode: '10f73512-622c-43d2-a0b4-e26f4e04a13e',
							embedId: 'ksys-app-bar-chart',
							templateId: 'ksys-app-bar-chart',
						},
						{
							applicationCode: 'e42ba1e3-35ba-4a3a-89d8-dbcf46ca3432',
							embedId: 'ksys-app-page-header',
							templateId: 'ksys-app-page-header',
						},
						{
							applicationCode: '62916354-1ad5-44b0-a512-1cec3b7c27ba',
							embedId: 'ksys-app-commentary',
							templateId: 'ksys-app-commentary',
						},
						{
							applicationCode: 'd639b4c9-0036-499a-ab2b-e4d79d3bb642',
							embedId: 'ksys-app-fund-managers',
							templateId: 'ksys-app-fund-managers',
						},
						{
							applicationCode: '38c8b9ff-a1e1-4bc6-9ac3-f26b3a69b56b',
							embedId: 'ksys-app-key-facts',
							templateId: 'ksys-app-key-facts',
						},
						{
							applicationCode: '610a7884-98bd-4b66-bec6-7d33b9a6bacd',
							embedId: 'ksys-app-document-list',
							templateId: 'ksys-app-document-list',
						},
						{
							applicationCode: '204cb546-36d8-4a90-8782-9ac564792398',
							embedId: 'ksys-app-document-menu',
							templateId: 'ksys-app-document-menu',
						},
						{
							applicationCode: 'dd07d8e9-e76f-47b4-aea1-65b08a129955',
							embedId: 'ksys-app-document-explorer',
							templateId: 'ksys-app-document-explorer',
						},
						{
							applicationCode: 'b9de11bf-0325-4f07-9f48-9a66aaadbb9b',
							embedId: 'ksys-app-fund-cards',
							templateId: 'ksys-app-fund-cards',
						},
						{
							applicationCode: '1297c256-3d6d-405c-9c37-2b56e7255c3e',
							embedId: 'ksys-app-line-chart',
							templateId: 'ksys-app-line-chart',
						},
						{
							applicationCode: '6fc0e996-df00-4815-bd56-bda22fa9539b',
							embedId: 'ksys-app-pie-chart',
							templateId: 'ksys-app-pie-chart',
						},
						{
							applicationCode: '6e46a121-2c6b-449e-b743-be0a2b855ffb',
							embedId: 'ksys-app-key-facts-list',
							templateId: 'ksys-app-key-facts-list',
						},
						{
							applicationCode: '5a175d8d-57e4-462b-9f6d-4bbb88acf0fc',
							embedId: 'ksys-app-rating-scale',
							templateId: 'ksys-app-rating-scale',
						},
						{
							applicationCode: '594a7108-1c66-41a1-8904-98774fe6f03e',
							embedId: 'ksys-app-global-inputs',
							templateId: 'ksys-app-global-inputs',
						},
						{
							applicationCode: '4bbef078-b9eb-4d64-a347-64d373398f1e',
							embedId: 'ksys-app-data-download',
							templateId: 'ksys-app-data-download',
						},
						{
							applicationCode: '212095a2-c8e9-4feb-bd80-7d0402d2ee73',
							embedId: 'ksys-app-table',
							templateId: 'ksys-app-table',
						},
						{
							applicationCode: '10f73512-622c-43d2-a0b4-e26f4e04a13e',
							embedId: 'ksys-app-bar-chart',
							templateId: 'ksys-app-bar-chart',
						},
						{
							applicationCode: 'b93535f7-2dc5-4c4f-a627-cb462c1bf6ad',
							embedId: 'ksys-app-tabs',
							templateId: 'ksys-app-tabs',
						},
						{
							applicationCode: '52e41ff0-9792-4f13-aa56-e78523a4c434',
							embedId: 'ksys-app-finder',
							templateId: 'ksys-app-finder',
						},
						{
							applicationCode: 'fe94414a-d907-45cd-b7ee-6cd4f591d487',
							embedId: 'ksys-app-global-search',
							templateId: 'ksys-app-global-search',
						},
					],
				},
				endpointsConfiguration: {
					endpoints: {
						apps: {
							Initialize: {
								mockData: {
									enabled: true,
									defaultResponse: {
										manager: {
											app: {
												templateId: 'ksys-app-manager',
												configurationKey: 'default',
												styleKey: 'default',
												version: 'v1.0.0',
												templateCode: 'ksys-app-manager-v1.0.0',
												id: 'ksys-app-manager-v1.0.0|default|default',
											},
										},
										apps: [
											{
												app: {
													templateId: 'ksys-app-finder',
													configurationKey: 'header',
													styleKey: 'header',
													version: 'v4.0.0',
													templateCode: 'ksys-app-finder-v4.0.0',
													id: 'ksys-app-finder-v4.0.0|header|header',
													applicationCode: '52e41ff0-9792-4f13-aa56-e78523a4c434',
													assetRegister: [
														{
															fileName: 'AccordionCollapseIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'AccordionExpandIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'BackArrowIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'BlockRangeSeriesPointBottom.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'BlockRangeSeriesPointTop.svg',
															lastModified: '2023-04-14T11:44:22.000Z',
														},
														{
															fileName: 'Calendar.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'CalendarPlaceholder.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'CaretDown.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'CaretUp.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'CarouselNavArrowLeftIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'CarouselNavArrowRightIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ChangeDownIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ChangeUpIcon.svg',
															lastModified: '2023-04-14T11:44:22.000Z',
														},
														{
															fileName: 'CheckboxHalfIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'CheckboxIcon.svg',
															lastModified: '2023-04-14T11:44:22.000Z',
														},
														{
															fileName: 'CheckboxMarkedIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'Close.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'CopyLink.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'Cross.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'DeleteSavedSearch.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'DocumentIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'DocumentLabelIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'DownArrowIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'DownloadIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'DropdownChevronIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'DropdownChevronIconExpanded.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ErrorIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'FingerPrintIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'FundManagerPlaceholder.svg',
															lastModified: '2023-04-14T11:44:22.000Z',
														},
														{
															fileName: 'HomeIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'index.html',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'InformationIcon.svg',
															lastModified: '2023-04-14T11:44:22.000Z',
														},
														{
															fileName: 'IPhoneIcon.svg',
															lastModified: '2023-04-14T11:44:22.000Z',
														},
														{
															fileName: 'kurtosys.png',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'KurtosysLogo.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'LockIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'MagnificationGlass.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'main-ksys-app-finder.js',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'main-ksys-app-finder.js.LICENSE.txt',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'main-ksys-app-finder.js.map',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'MessageIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'NextMonth.svg',
															lastModified: '2023-04-14T11:44:22.000Z',
														},
														{
															fileName: 'PagingFirstArrow.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'PagingFirstArrowDisabled.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'PagingLastArrow.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'PagingLastArrowDisabled.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'PagingLeftArrow.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'PagingLeftArrowDisabled.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'PagingRightArrow.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'PagingRightArrowDisabled.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'PersonOutlineIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'PreviousMonth.svg',
															lastModified: '2023-04-14T11:44:22.000Z',
														},
														{
															fileName: 'RadioButtonsCross.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'RadioButtonsTick.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'RefreshIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SavedSearchList.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SavedSearchNotificationOff.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SavedSearchNotificationOn.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SavedSearchSave.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SavedSearchToggleClosed.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SavedSearchToggleOpen.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SelectedResultClear.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ShieldIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SortAscending.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SortDescending.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SortLoading.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SortUnset.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'Spinner.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'StarEmpty.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'StarFilledDefault.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'StarFilledGood.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'StarFilledModerate.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'StarFilledOutstanding.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'StarFilledPoor.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'SuccessIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ToggleActive.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ToggleActiveMobile.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ToggleDisabledInactive.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ToggleDisabledInactiveMobile.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ToggleInactive.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ToggleInactiveMobile.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'ToggleLoading.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
														{
															fileName: 'UpArrowIcon.svg',
															lastModified: '2023-04-14T11:44:23.000Z',
														},
													],
												},
												applicationConfiguration: {
													assetOverrides: {
														base: {
															dropdown: {
																caretDown: 'caretDown.svg',
																caretUp: 'caretUp.svg',
															},
															search: {
																cross: 'searchClear.svg',
															},
														},
													},
													components: {
														app: {
															cardProps: {},
															gridProps: {
																grid: {
																	rows: [
																		{
																			columns: [
																				{
																					components: [
																						{
																							key: 'filters',
																							style: {
																								align: {
																									items: 'center',
																								},
																								display: 'flex',
																								min: {
																									width: '130px',
																								},
																							},
																						},
																						{
																							key: 'search',
																							style: {
																								align: {
																									items: 'center',
																								},
																								display: 'flex',
																								flex: {
																									grow: '1',
																								},
																								overrides: [
																									{
																										selector:
																											'> section',
																										style: {
																											flex: {
																												_value: '1',
																											},
																										},
																									},
																								],
																							},
																						},
																						{
																							key: 'searchAction',
																							style: {
																								align: {
																									items: 'center',
																								},
																								display: 'flex',
																							},
																						},
																					],
																					style: {
																						display: 'flex',
																					},
																				},
																			],
																		},
																	],
																},
															},
														},
														filters: {
															expandable: false,
															fundLists: ['{input:segmentation}'],
															options: [
																{
																	allowedValues: ['Mutual fund', 'ETF'],
																	entityType: 'CLSS',
																	id: 'product_type',
																	mode: 'single',
																	placeholder: 'Product Type',
																	property: 'product_type',
																},
															],
														},
														redirects: {
															baseInputs: [
																{
																	key: 'isin',
																	query: {
																		code: 'isin',
																		entityType: 'CLSS',
																		queryOptionsType: 'property',
																	},
																},
																{
																	key: 'fundName',
																	query: {
																		code: 'fund_name',
																		entityType: 'CLSS',
																		queryOptionsType: 'property',
																		transforms: [
																			{
																				caseType: 'param',
																				transformOptionsType: 'case',
																			},
																		],
																	},
																},
															],
															options: [
																{
																	key: 'fundPage',
																	redirect: {
																		defaultUrl:
																			'{originPath}/funds/{baseInput:fundName}/',
																	},
																},
																{
																	key: 'fundCentre',
																	redirect: {
																		defaultUrl: '{originPath}/fund-centre/',
																		options: {
																			queryStringParams: [
																				{
																					key: 'term',
																					value: '{search:term}',
																				},
																			],
																		},
																	},
																},
															],
															searchAction: {
																redirectKey: 'fundCentre',
															},
															searchResultChildItem: {
																redirectKey: 'fundPage',
															},
														},
														search: {
															props: {
																alwaysHideResults: false,
																minCharacterTrigger: 3,
																placeholder: 'Search fund or ISIN...',
																showSelectedResults: true,
															},
															result: {
																searchRequest: {
																	observedFilters: ['product_type'],
																	searchEntitiesId: 'classSearch',
																},
															},
														},
														searchAction: {
															props: {
																className: 'link',
																iconProps: {
																	accessibilityText: 'Search funds',
																	src: 'https://api-eu.fundpress.io/applicationManager/apps/df920a97-6a48-4e40-9ef9-1a54a06521e9/SearchIcon.svg',
																},
															},
														},
														searchResultItem: {
															label: {
																left: [
																	{
																		queryOptionsType: 'none',
																		subQueries: [
																			{
																				code: 'fund_name',
																				entityType: 'CLSS',
																				queryId: 'fund_name',
																				queryOptionsType: 'property',
																			},
																			{
																				code: 'share_class',
																				entityType: 'CLSS',
																				queryId: 'share_class',
																				queryOptionsType: 'property',
																			},
																			{
																				code: 'isin',
																				entityType: 'CLSS',
																				queryId: 'isin',
																				queryOptionsType: 'property',
																			},
																		],
																		value: '{sub:fund_name} | {sub:share_class} | {sub:isin}',
																	},
																],
															},
														},
													},
													data: {
														context: {
															entities: [
																{
																	id: 'classSearch',
																	inputs: [
																		{
																			key: 'segmentation',
																			query: {
																				key: 'segmentation',
																				queryOptionsType: 'input',
																			},
																		},
																	],
																	requestBody: {
																		fundList: ['{input:segmentation}'],
																		identifierSearch: {
																			fuzzy: true,
																			properties: ['fund_name', 'isin'],
																			value: '{input:term}',
																		},
																		include: {},
																		includeProperties: [
																			'fund_name',
																			'share_class',
																			'isin',
																		],
																		limit: 10,
																		preserveOriginal: true,
																		search: [],
																		sort: [
																			{
																				direction: 'ASC',
																				key: 'fund_name',
																			},
																		],
																		type: 'CLSS',
																	},
																},
															],
														},
													},
												},
												applicationStyles: {
													components: {
														searchResultItem: {
															children: {
																wrapper: {
																	border: {
																		top: {
																			_value: '1px solid var(--mid-gray)',
																		},
																	},
																	margin: {
																		_value: '0 var(--space-md1)',
																	},
																	padding: {
																		_value: 'var(--space-xs2) 0',
																	},
																},
															},
														},
													},
													theme: {
														base: {
															card: {
																children: {
																	body: {
																		background: {
																			_value: 'var(--white)',
																		},
																		border: {
																			_value: '1px solid var(--mid-gray)',
																			radius: '100px',
																		},
																		min: {
																			height: '48px',
																		},
																		padding: {
																			_value: 'var(--space-xs1)',
																		},
																		selectors: [
																			{
																				':has': {
																					_parameters: ['input:focus'],
																					border: {
																						color: 'var(--blue-base)',
																					},
																					box: {
																						shadow: 'var(--blue-base) 0px 0px 0px 1px',
																					},
																				},
																			},
																		],
																	},
																	title: {
																		color: 'var(--white)',
																		font: {
																			family: 'var(--headline2-font-family)',
																			size: 'var(--headline2-font-size)',
																			weight: 'var(--headline2-font-weight)',
																		},
																		letterSpacing:
																			'var(--headline2-letter-spacing)',
																		lineHeight: 'var(--headline2-line-height)',
																	},
																	wrapper: {
																		breakpoints: [
																			{
																				max: '767px',
																				media: ['screen'],
																				style: {
																					display: 'none',
																				},
																			},
																		],
																		padding: {
																			_value: '0',
																		},
																	},
																},
															},
															dropdown: {
																children: {
																	headerTitle: {
																		color: 'var(--azaela)',
																		font: {
																			family: 'var(--body3-regular-font-family)',
																			size: 'var(--body3-regular-font-size)',
																			weight: 'var(--body3-regular-font-weight)',
																		},
																		letterSpacing:
																			'var(--body3-regular-letter-Spacing)',
																		lineHeight: 'var(--body3-regular-line-height)',
																	},
																	list: {
																		min: {
																			width: 'initial',
																		},
																		padding: {
																			_value: '0',
																		},
																		width: '100%',
																	},
																	wrapper: {
																		background: {
																			_value: ' var(--azaela-light)',
																		},
																		border: {
																			_value: '1px solid rgba(169, 84, 108, .2)',
																			radius: '100px',
																		},
																		cursor: 'pointer',
																		min: {
																			height: '38px',
																		},
																		padding: {
																			_value: 'var(--space-xs2) var(--space-sm2)',
																		},
																	},
																},
															},
															icon: {
																children: {
																	wrapper: {
																		margin: {
																			right: '16px',
																		},
																	},
																},
															},
															search: {
																children: {
																	resultCard: {
																		background: {
																			_value: 'var(--white)',
																		},
																		border: {
																			_value: '0.5px solid var(--silver-gray)',
																			radius: '7px',
																		},
																		box: {
																			shadow: '0px 4px 15px rgba(0, 0, 0, 0.08)',
																		},
																		breakpoints: [
																			{
																				max: '991px',
																				media: ['screen'],
																				style: {
																					max: {
																						height: '340px',
																					},
																				},
																			},
																		],
																		left: '-132px',
																		listStyle: {
																			_value: 'none',
																		},
																		max: {
																			height: '240px',
																		},
																		overflow: {
																			y: 'auto',
																		},
																		overrides: [
																			{
																				selector: 'li',
																				style: {
																					selectors: [
																						{
																							':first-child': {
																								overrides: [
																									{
																										selector:
																											'[data-selector="result-item-wrapper"]',
																										style: {
																											border: {
																												top: {
																													_value: 'none',
																												},
																											},
																										},
																									},
																								],
																							},
																						},
																						{
																							':hover': {
																								background: {
																									_value: 'var(--blue-light-light)',
																								},
																								cursor: 'pointer',
																							},
																						},
																						{
																							':focus': {
																								background: {
																									_value: 'var(--blue-light-light)',
																								},
																							},
																						},
																					],
																				},
																			},
																			{
																				selector: 'li:first-child',
																				style: {
																					border: {
																						_value: 'none',
																					},
																					padding: {
																						top: '20px',
																					},
																				},
																			},
																			{
																				selector: 'li:last-child',
																				style: {
																					padding: {
																						bottom: '20px',
																					},
																				},
																			},
																		],
																		padding: {
																			_value: '0',
																		},
																		position: 'absolute',
																		top: '40px',
																		width: 'calc(100% + 180px)',
																		zIndex: '10',
																	},
																	searchBar: {
																		display: 'flex',
																		min: {
																			height: '36px',
																		},
																		overrides: [
																			{
																				selector: 'input',
																				style: {
																					border: {
																						_value: 'none',
																					},
																					color: 'var(--black)',
																					font: {
																						size: 'var(--body3-regular-font-size)',
																					},
																					padding: {
																						_value: '0 var(--space-sm1)',
																					},
																					selectors: [
																						{
																							':focus': {
																								_raw: '@-moz-document url-prefix() { border-color: var(--blue-base); box-shadow: var(--blue-base) 0px 0px 0px 1px; }',
																								border: {
																									color: 'none !important',
																								},
																								box: {
																									shadow: 'none !important',
																								},
																							},
																						},
																					],
																				},
																			},
																			{
																				selector: 'input::placeholder',
																				style: {
																					color: 'var(--dark-gray)',
																				},
																			},
																			{
																				selector:
																					'input::-webkit-input-placeholder',
																				style: {
																					color: 'var(--dark-gray)',
																				},
																			},
																		],
																	},
																	searchClear: {
																		background: {
																			_value: 'none',
																		},
																		border: {
																			_value: 'none',
																		},
																		box: {
																			shadow: 'none',
																		},
																		cursor: 'pointer',
																		overrides: [
																			{
																				selector:
																					'[data-selector="base-icon-wrapper"]',
																				style: {
																					margin: {
																						right: '0',
																					},
																				},
																			},
																			{
																				selector: 'img',
																				style: {
																					height: '16px',
																					width: '16px',
																				},
																			},
																		],
																		padding: {
																			_value: '0px 16px',
																		},
																	},
																	wrapper: {
																		position: 'relative',
																	},
																},
															},
														},
													},
												},
												inputConfiguration: {
													inputs: [
														{
															inputCode: 'culture',
															inputDataType: 'STRG',
															inputName: 'Culture',
															inputSource: {
																inputType: 'custom values',
																values: [],
															},
														},
														{
															inputCode: 'client_code',
															inputDataType: 'STRG',
															inputName: 'Client Code',
															inputSource: {
																inputType: 'custom values',
																values: [],
															},
														},
													],
													urlParamMappings: [],
												},
												authentication: {
													serviceUrl: 'https://api-eu.kurtosys.app',
													token: '617793dd-644b-4f9b-85aa-df2a6789b61a',
												},
												applicationClientConfigurationIds: [27, 33],
											},
											{
												app: {
													templateId: 'ksys-app-grid',
													configurationKey: 'productPage_header',
													styleKey: 'productPage_header',
													version: 'v4.0.0',
													templateCode: 'ksys-app-grid-v4.0.0',
													id: 'ksys-app-grid-v4.0.0|productPage_header|productPage_header',
													applicationCode: '4397934b-2bed-45e8-9825-a8fa66dedeab',
													assetRegister: [
														{
															fileName: 'AccordionCollapseIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'AccordionExpandIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'BackArrowIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'BlockRangeSeriesPointBottom.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'BlockRangeSeriesPointTop.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'Calendar.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CalendarPlaceholder.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CaretDown.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CaretUp.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CarouselNavArrowLeftIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CarouselNavArrowRightIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ChangeDownIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ChangeUpIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CheckboxHalfIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CheckboxIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CheckboxMarkedIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'Close.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CopyLink.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'Cross.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DeleteSavedSearch.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DocumentIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DocumentLabelIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DownArrowIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DownloadIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DropdownChevronIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DropdownChevronIconExpanded.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ErrorIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'FingerPrintIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'FundManagerPlaceholder.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'HomeIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'index.html',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'InformationIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'IPhoneIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'kurtosys.png',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'KurtosysLogo.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'LockIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'MagnificationGlass.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'main-ksys-app-grid.js',
															lastModified: '2023-04-18T11:18:15.000Z',
														},
														{
															fileName: 'main-ksys-app-grid.js.LICENSE.txt',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'main-ksys-app-grid.js.map',
															lastModified: '2023-04-18T11:18:15.000Z',
														},
														{
															fileName: 'MessageIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'NextMonth.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingFirstArrow.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingFirstArrowDisabled.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingLastArrow.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingLastArrowDisabled.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingLeftArrow.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingLeftArrowDisabled.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingRightArrow.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingRightArrowDisabled.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PersonOutlineIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PreviousMonth.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'RadioButtonsCross.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'RadioButtonsTick.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'RefreshIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchList.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchNotificationOff.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchNotificationOn.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchSave.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchToggleClosed.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchToggleOpen.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SelectedResultClear.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ShieldIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SortAscending.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SortDescending.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SortLoading.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SortUnset.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'Spinner.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarEmpty.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarFilledDefault.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarFilledGood.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarFilledModerate.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarFilledOutstanding.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarFilledPoor.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SuccessIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleActive.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleActiveMobile.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleDisabledInactive.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleDisabledInactiveMobile.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleInactive.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleInactiveMobile.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleLoading.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'UpArrowIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
													],
												},
												applicationConfiguration: {
													components: {
														app: {
															gridProps: {
																grid: {
																	rows: [
																		{
																			columns: [
																				{
																					components: [
																						{
																							key: 'base.applicationEmbed',
																							props: {
																								configurationKey:
																									'pageHeader',
																								embedId:
																									'ksys-app-page-header',
																								styleKey: 'pageHeader',
																							},
																						},
																					],
																					style: {
																						flex: {
																							_value: '1 1 75%',
																						},
																					},
																				},
																				{
																					components: [
																						{
																							key: 'base.applicationEmbed',
																							props: {
																								configurationKey:
																									'pageHeaderDocuments',
																								embedId:
																									'ksys-app-document-menu',
																								styleKey:
																									'pageHeaderDocuments',
																							},
																						},
																						{
																							key: 'base.applicationEmbed',
																							props: {
																								configurationKey:
																									'howToInvestButton',
																								embedId:
																									'ksys-app-commentary',
																								styleKey:
																									'howToInvestButton',
																							},
																						},
																					],
																					style: {
																						align: {
																							items: 'flex-end',
																						},
																						column: {
																							gap: '16px',
																						},
																						display: 'flex',
																						flex: {
																							_value: '1 1 25%',
																						},
																						padding: {
																							bottom: '48px',
																						},
																					},
																				},
																			],
																			name: 'Header',
																			style: {
																				align: {
																					items: 'flex-end',
																				},
																				display: 'flex',
																				flex: {
																					basis: 'auto',
																					grow: '1',
																					shrink: '1',
																					wrap: 'wrap',
																				},
																				row: {
																					gap: 'var(--space-md2)',
																				},
																			},
																		},
																	],
																	style: {
																		display: 'flex',
																		flex: {
																			direction: 'column',
																		},
																	},
																},
															},
															noDataOptions: {
																hide: false,
															},
														},
													},
												},
												applicationStyles: {
													theme: {
														base: {},
													},
												},
												inputConfiguration: {
													inputs: [
														{
															inputCode: 'culture',
															inputDataType: 'STRG',
															inputName: 'Culture',
															inputSource: {
																inputType: 'custom values',
																values: [],
															},
														},
														{
															inputCode: 'client_code',
															inputDataType: 'STRG',
															inputName: 'Client Code',
															inputSource: {
																inputType: 'custom values',
																values: [],
															},
														},
													],
													urlParamMappings: [],
												},
												authentication: {
													serviceUrl: 'https://api-eu.kurtosys.app',
													token: '617793dd-644b-4f9b-85aa-df2a6789b61a',
												},
												applicationClientConfigurationIds: [27, 33],
											},
											{
												app: {
													templateId: 'ksys-app-tabs',
													configurationKey: 'productPageTabs',
													styleKey: 'default',
													version: 'v4.0.0',
													templateCode: 'ksys-app-tabs-v4.0.0',
													id: 'ksys-app-tabs-v4.0.0|productPageTabs|default',
													applicationCode: 'b93535f7-2dc5-4c4f-a627-cb462c1bf6ad',
													assetRegister: [
														{
															fileName: 'AccordionCollapseIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'AccordionExpandIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'BackArrowIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'BlockRangeSeriesPointBottom.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'BlockRangeSeriesPointTop.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'Calendar.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'CalendarPlaceholder.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'CaretDown.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'CaretUp.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'CarouselNavArrowLeftIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'CarouselNavArrowRightIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ChangeDownIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ChangeUpIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'CheckboxHalfIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'CheckboxIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'CheckboxMarkedIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'Close.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'CopyLink.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'Cross.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'DeleteSavedSearch.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'DocumentIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'DocumentLabelIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'DownArrowIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'DownloadIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'DropdownChevronIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'DropdownChevronIconExpanded.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ErrorIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'FingerPrintIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'FundManagerPlaceholder.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'HomeIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'index.html',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'InformationIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'IPhoneIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'kurtosys.png',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'KurtosysLogo.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'LockIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'MagnificationGlass.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'main-ksys-app-tabs.js',
															lastModified: '2023-04-19T10:30:13.000Z',
														},
														{
															fileName: 'main-ksys-app-tabs.js.LICENSE.txt',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'main-ksys-app-tabs.js.map',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'MessageIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'NextMonth.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'PagingFirstArrow.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'PagingFirstArrowDisabled.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'PagingLastArrow.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'PagingLastArrowDisabled.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'PagingLeftArrow.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'PagingLeftArrowDisabled.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'PagingRightArrow.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'PagingRightArrowDisabled.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'PersonOutlineIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'PreviousMonth.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'RadioButtonsCross.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'RadioButtonsTick.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'RefreshIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SavedSearchList.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SavedSearchNotificationOff.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SavedSearchNotificationOn.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SavedSearchSave.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SavedSearchToggleClosed.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SavedSearchToggleOpen.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SelectedResultClear.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ShieldIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SortAscending.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SortDescending.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SortLoading.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SortUnset.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'Spinner.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'StarEmpty.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'StarFilledDefault.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'StarFilledGood.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'StarFilledModerate.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'StarFilledOutstanding.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'StarFilledPoor.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'SuccessIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ToggleActive.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ToggleActiveMobile.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ToggleDisabledInactive.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ToggleDisabledInactiveMobile.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ToggleInactive.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ToggleInactiveMobile.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'ToggleLoading.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
														{
															fileName: 'UpArrowIcon.svg',
															lastModified: '2023-04-19T10:30:12.000Z',
														},
													],
												},
												applicationConfiguration: {
													components: {
														app: {
															activeTabManagement: {
																___urlRewrite: {
																	defaultUrl: '{originPath}#{activeTab}',
																	options: {
																		preserveOrigin: true,
																		preserveOriginPath: true,
																		preserveOriginPathWithOverrides: true,
																	},
																},
																initialValue: {
																	key: 'hash',
																	queryOptionsType: 'input',
																	transforms: [
																		{
																			replaceTransformOptions: {
																				replaceValue: '',
																				searchValue: '#',
																			},
																			transformOptionsType: 'replace',
																		},
																	],
																},
															},
															noDataOptions: {
																hide: false,
															},
														},
														tabs: {
															activeTab: 'overview',
															children: [
																{
																	grid: [
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageOverview_importantNotice',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageOverview_fundFacts',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageOverview_fundObjectiveManagerInsights',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageOverview_performanceEvolution_top5Sectors',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageOverview_esgScore_responsibleInvesting',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageOverview_morningstarRating',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_riskCharacteristics',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_keyDocuments',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_featuredFunds',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_investmentInstitute',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																	],
																	gridContainerOptions: {
																		container: true,
																		rowSpacing: 0,
																		spacing: 0,
																	},
																	header: 'Overview',
																	icon: {
																		assetQuery: {
																			queryOptionsType: 'none',
																		},
																	},
																	tabKey: 'overview',
																},
																{
																	grid: [
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageWhyThisFund_fundObjective',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageWhyThisFund_reasonsToInvest',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageWhyThisFund_keyFigures',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageWhyThisFund_fundManager',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageWhyThisFund_marketingDocuments',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_keyDocuments',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_featuredFunds',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_investmentInstitute',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageWhyThisFund_disclaimers',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																	],
																	gridContainerOptions: {
																		container: true,
																		rowSpacing: 0,
																		spacing: 0,
																	},
																	header: 'Why This Fund',
																	icon: {
																		assetQuery: {
																			queryOptionsType: 'none',
																		},
																	},
																	tabKey: 'whyThisFund',
																},
																{
																	grid: [
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageCommentary_fundManager',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageCommentary_fundManagerInsights',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageCommentary_tweetThis',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageCommentary_keyFigures',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageCommentary_keyExtracts',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageCommentary_fundManagerFooter',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				className: 'alt_bg',
																				configurationKey:
																					'productPage_keyDocuments',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				className: 'light_bg',
																				configurationKey:
																					'productPage_featuredFunds',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_investmentInstitute',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageWhyThisFund_disclaimers',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																	],
																	gridContainerOptions: {
																		container: true,
																		rowSpacing: 0,
																		spacing: 0,
																	},
																	header: 'Commentary',
																	icon: {
																		assetQuery: {
																			queryOptionsType: 'none',
																		},
																	},
																	tabKey: 'commentary',
																},
																{
																	grid: [
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePerformanceRisk_annualPerformance',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePerformanceRisk_performanceEvolution',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePerformanceRisk_cumulativePerformance',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePerformanceRisk_annualisedPerformance',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePerformanceRisk_navPriceEvolution',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePerformanceRisk_riskIndicators',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePerformanceRisk_benchmark',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_riskCharacteristics',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_keyDocuments',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_featuredFunds',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_investmentInstitute',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePerformanceRisk_disclaimers',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																	],
																	gridContainerOptions: {
																		container: true,
																		rowSpacing: 0,
																		spacing: 0,
																	},
																	header: 'Performance & Risk',
																	icon: {
																		assetQuery: {
																			queryOptionsType: 'none',
																		},
																	},
																	tabKey: 'performanceRisk',
																},
																{
																	grid: [
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePortfolioAnalysis_sectorBreakdown',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePortfolioAnalysis_regionBreakdown',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePortfolioAnalysis_exposureByMarketCap',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePortfolioAnalysis_top10Holdings',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePortfolioAnalysis_fixedIncomeRatingBreakdown',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePortfolioAnalysis_totalHoldings',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePortfolioAnalysis_currencyBreakdown',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePortfolioAnalysis_maturityBreakdown',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_keyDocuments',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_featuredFunds',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_investmentInstitute',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPagePortfolioAnalysis_disclaimers',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																	],
																	gridContainerOptions: {
																		container: true,
																		rowSpacing: 0,
																		spacing: 0,
																	},
																	header: 'Portfolio Analysis',
																	icon: {
																		assetQuery: {
																			queryOptionsType: 'none',
																		},
																	},
																	tabKey: 'portfolioAnalysis',
																},
																{
																	grid: [
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_distributionRegions',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_fees',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_dividendRecord',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_subscriptionRedemption',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_administration',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_keyDocuments',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_featuredFunds',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_investmentInstitute',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_disclaimers',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																	],
																	gridContainerOptions: {
																		container: true,
																		rowSpacing: 0,
																		spacing: 0,
																	},
																	header: 'Administration & Fees',
																	icon: {
																		assetQuery: {
																			queryOptionsType: 'none',
																		},
																	},
																	queries: {
																		hide: {
																			key: 'productType',
																			queryOptionsType: 'input',
																			transforms: [
																				{
																					conditional: {
																						conditions: [
																							{
																								fieldQuery: {
																									key: 'productType',
																									queryOptionsType:
																										'input',
																								},
																								operator: '=',
																								value: 'ETF',
																							},
																						],
																					},
																					transformOptionsType: 'conditional',
																				},
																			],
																		},
																	},
																	tabKey: 'administrationFees',
																},
																{
																	grid: [
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_fees',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_dividendRecord',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_tradingInformation',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_authorisedParticipants',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_distributionRegions',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_subscriptionRedemption',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_administration',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				className: 'alt_bg',
																				configurationKey:
																					'productPage_keyDocuments',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				className: 'light_bg',
																				configurationKey:
																					'productPage_featuredFunds',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_investmentInstitute',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageAdministrationFees_disclaimers',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																	],
																	gridContainerOptions: {
																		container: true,
																		rowSpacing: 0,
																		spacing: 0,
																	},
																	header: 'Administration & Fees',
																	icon: {
																		assetQuery: {
																			queryOptionsType: 'none',
																		},
																	},
																	queries: {
																		hide: {
																			key: 'productType',
																			queryOptionsType: 'input',
																			transforms: [
																				{
																					conditional: {
																						conditions: [
																							{
																								fieldQuery: {
																									key: 'productType',
																									queryOptionsType:
																										'input',
																								},
																								operator: '!=',
																								value: 'ETF',
																							},
																						],
																					},
																					transformOptionsType: 'conditional',
																				},
																			],
																		},
																	},
																	tabKey: 'administrationFees',
																},
																{
																	grid: [
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageDocuments_documentExplorer',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageDocuments_marketingDocuments',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_keyDocuments',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_featuredFunds',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPage_investmentInstitute',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																		{
																			applicationEmbed: {
																				configurationKey:
																					'productPageDocuments_disclaimers',
																				embedId: 'ksys-app-grid',
																				styleKey: 'default',
																				templateId: 'ksys-app-grid',
																			},
																			options: {
																				noDataOptions: {
																					hideLevel: 'gridItem',
																				},
																			},
																		},
																	],
																	gridContainerOptions: {
																		container: true,
																		rowSpacing: 0,
																		spacing: 0,
																	},
																	header: 'Documents',
																	icon: {
																		assetQuery: {
																			queryOptionsType: 'none',
																		},
																	},
																	tabKey: 'documents',
																},
															],
															componentStyle: {},
															headersMode: 'tabs',
														},
													},
													culture: {
														base: 'Default',
														default: 'en-GB',
													},
												},
												applicationStyles: {
													components: {
														tabs: {
															tabPanel: {
																styles: {
																	padding: 0,
																},
															},
															tabsContainer: {
																styles: {
																	border: {
																		bottom: {
																			_value: '1px solid var(--mid-gray)',
																		},
																	},
																	display: 'flex',
																	justifyContent: 'center',
																},
															},
														},
													},
													theme: {
														base: {
															application: {
																overrides: [
																	{
																		selector:
																			'.MuiGrid2-container > .MuiGrid2-container > .MuiGrid2-container',
																		style: {
																			_raw: '& {     @media screen and (max-width: 1199px) {         max-width: 960px;     }     @media screen and (max-width: 991px) {         max-width: 720px;     }     @media screen and (max-width: 767px) {         max-width: 536px;     }     @media screen and (max-width: 572px) {         max-width: 527px;     } }',
																			margin: {
																				_value: '0 auto',
																			},
																			max: {
																				width: '1140px',
																			},
																			width: '100%',
																		},
																	},
																],
															},
															card: {
																children: {
																	body: {
																		overrides: [
																			{
																				selector: '.tabs-container',
																				style: {
																					border: {
																						bottom: {
																							_value: '1px solid var(--mid-gray)',
																						},
																					},
																				},
																			},
																		],
																	},
																	wrapper: {
																		overrides: [
																			{
																				selector: '.tabs-tab .Mui-selected',
																				style: {
																					color: 'var(--blue-light)',
																					font: {
																						weight: '600',
																					},
																				},
																			},
																			{
																				selector: '.MuiTabs-scroller',
																				style: {
																					width: '1140px',
																				},
																			},
																			{
																				selector: '.tabs-tab',
																				style: {
																					color: 'var(--black)',
																					font: {
																						family: 'var(--body2-regular-font-family)',
																						size: 'var(--body2-regular-font-size)',
																						weight: 'var(--body2-regular-font-weight)',
																					},
																					padding: {
																						_value: '0',
																					},
																					text: {
																						transform: 'capitalize',
																					},
																				},
																			},
																			{
																				selector: '.MuiTabs-flexContainer',
																				style: {
																					align: {
																						items: 'center',
																					},
																					breakpoints: [
																						{
																							max: '1199px',
																							media: ['screen'],
																							style: {
																								max: {
																									width: '960px',
																								},
																							},
																						},
																						{
																							max: '991px',
																							media: ['screen'],
																							style: {
																								max: {
																									width: '720px',
																								},
																							},
																						},
																						{
																							max: '767px',
																							media: ['screen'],
																							style: {
																								max: {
																									width: '536px',
																								},
																							},
																						},
																						{
																							max: '572px',
																							media: ['screen'],
																							style: {
																								max: {
																									width: '527px',
																								},
																							},
																						},
																					],
																					column: {
																						gap: 'var(--space-md2)',
																					},
																					display: 'flex',
																					justifyContent: 'space-between',
																					margin: {
																						_value: '0 auto',
																					},
																					max: {
																						width: '1140px',
																					},
																					overflow: {
																						x: 'auto',
																					},
																				},
																			},
																			{
																				selector: 'button > img',
																				style: {
																					display: 'none',
																				},
																			},
																			{
																				selector: 'button > img',
																				style: {
																					color: 'var(--black)',
																					font: {
																						family: 'var(--body2-regular-font-family)',
																						size: 'var(--body2-regular-font-size)',
																						weight: 'var(--body2-regular-font-weight)',
																					},
																					text: {
																						transform: 'capitalize',
																					},
																				},
																			},
																		],
																		padding: {
																			_value: '0',
																		},
																	},
																},
															},
														},
														mui: {
															components: {},
														},
													},
												},
												inputConfiguration: {
													inputs: [
														{
															inputCode: 'culture',
															inputDataType: 'STRG',
															inputName: 'Culture',
															inputSource: {
																inputType: 'custom values',
																values: [],
															},
														},
														{
															inputCode: 'client_code',
															inputDataType: 'STRG',
															inputName: 'Client Code',
															inputSource: {
																inputType: 'custom values',
																values: [],
															},
														},
													],
													urlParamMappings: [],
												},
												authentication: {
													serviceUrl: 'https://api-eu.kurtosys.app',
													token: '617793dd-644b-4f9b-85aa-df2a6789b61a',
												},
												applicationClientConfigurationIds: [27, 33],
											},
											{
												app: {
													templateId: 'ksys-app-grid',
													configurationKey: 'howToInvestPopup',
													styleKey: 'default',
													version: 'v4.0.0',
													templateCode: 'ksys-app-grid-v4.0.0',
													id: 'ksys-app-grid-v4.0.0|howToInvestPopup|default',
													applicationCode: '4397934b-2bed-45e8-9825-a8fa66dedeab',
													assetRegister: [
														{
															fileName: 'AccordionCollapseIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'AccordionExpandIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'BackArrowIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'BlockRangeSeriesPointBottom.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'BlockRangeSeriesPointTop.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'Calendar.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CalendarPlaceholder.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CaretDown.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CaretUp.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CarouselNavArrowLeftIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CarouselNavArrowRightIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ChangeDownIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ChangeUpIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CheckboxHalfIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CheckboxIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CheckboxMarkedIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'Close.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'CopyLink.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'Cross.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DeleteSavedSearch.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DocumentIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DocumentLabelIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DownArrowIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DownloadIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DropdownChevronIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'DropdownChevronIconExpanded.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ErrorIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'FingerPrintIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'FundManagerPlaceholder.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'HomeIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'index.html',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'InformationIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'IPhoneIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'kurtosys.png',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'KurtosysLogo.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'LockIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'MagnificationGlass.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'main-ksys-app-grid.js',
															lastModified: '2023-04-18T11:18:15.000Z',
														},
														{
															fileName: 'main-ksys-app-grid.js.LICENSE.txt',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'main-ksys-app-grid.js.map',
															lastModified: '2023-04-18T11:18:15.000Z',
														},
														{
															fileName: 'MessageIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'NextMonth.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingFirstArrow.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingFirstArrowDisabled.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingLastArrow.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingLastArrowDisabled.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingLeftArrow.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingLeftArrowDisabled.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingRightArrow.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PagingRightArrowDisabled.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PersonOutlineIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'PreviousMonth.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'RadioButtonsCross.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'RadioButtonsTick.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'RefreshIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchList.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchNotificationOff.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchNotificationOn.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchSave.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchToggleClosed.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SavedSearchToggleOpen.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SelectedResultClear.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ShieldIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SortAscending.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SortDescending.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SortLoading.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SortUnset.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'Spinner.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarEmpty.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarFilledDefault.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarFilledGood.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarFilledModerate.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarFilledOutstanding.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'StarFilledPoor.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'SuccessIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleActive.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleActiveMobile.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleDisabledInactive.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleDisabledInactiveMobile.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleInactive.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleInactiveMobile.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'ToggleLoading.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
														{
															fileName: 'UpArrowIcon.svg',
															lastModified: '2023-04-18T11:18:14.000Z',
														},
													],
												},
												applicationConfiguration: {
													components: {
														app: {
															gridProps: {
																grid: {
																	rows: [
																		{
																			columns: [
																				{
																					components: [
																						{
																							key: 'base.applicationEmbed',
																							props: {
																								configurationKey:
																									'howToInvest',
																								embedId:
																									'ksys-app-document-list',
																								styleKey: 'howToInvest',
																							},
																						},
																					],
																					style: {
																						grid: {
																							column: {
																								_value: '1/-1',
																							},
																						},
																					},
																				},
																			],
																		},
																		{
																			columns: [
																				{
																					components: [
																						{
																							key: 'base.applicationEmbed',
																							props: {
																								configurationKey:
																									'platformsUk',
																								embedId:
																									'ksys-app-commentary',
																								styleKey: 'howToInvest',
																							},
																						},
																					],
																					style: {
																						grid: {
																							column: {
																								_value: '1/-1',
																							},
																						},
																					},
																				},
																			],
																		},
																		{
																			columns: [
																				{
																					components: [
																						{
																							key: 'base.applicationEmbed',
																							props: {
																								configurationKey:
																									'contactUsMfOtherUK',
																								embedId:
																									'ksys-app-commentary',
																								styleKey: 'howToInvest',
																							},
																						},
																					],
																					style: {
																						grid: {
																							column: {
																								_value: '1/-1',
																							},
																						},
																					},
																				},
																			],
																		},
																	],
																},
															},
															noDataOptions: {
																hide: false,
															},
														},
													},
												},
												applicationStyles: {
													theme: {
														base: {
															card: {
																children: {
																	title: {
																		margin: {
																			bottom: 'var(--space-sm1)',
																		},
																	},
																},
															},
															grid: {
																children: {
																	column: {
																		breakpoints: [
																			{
																				max: 'var(--breakpoint-sm)',
																				media: ['screen'],
																				style: {
																					border: {
																						radius: '0',
																					},
																				},
																			},
																		],
																	},
																	wrapper: {
																		background: {
																			color: 'var(--white)',
																		},
																		overrides: [
																			{
																				selector:
																					'> [data-selector="base-grid-row"]',
																				style: {
																					_raw: '& {     @media screen and (max-width: 1199px) {         max-width: 960px;     }     @media screen and (max-width: 991px) {         max-width: 720px;     }     @media screen and (max-width: 767px) {         max-width: 536px;     }     @media screen and (max-width: 572px) {         max-width: 527px;     } }',
																					display: 'grid',
																					grid: {
																						column: {
																							gap: 'var(--space-md2)',
																						},
																						row: {
																							gap: 'var(--space-md2)',
																						},
																						template: {
																							columns: 'repeat(12, 1fr)',
																						},
																					},
																					margin: {
																						_value: '0 auto',
																					},
																					max: {
																						width: '1140px',
																					},
																					width: '100%',
																				},
																			},
																		],
																		selectors: {
																			':not': {
																				_parameters: [
																					':has(.-ksys-base-application)',
																				],
																				min: {
																					height: '0',
																				},
																				overrides: [
																					{
																						selector:
																							'[data-selector="base-card-header"]',
																						style: {
																							display: 'none',
																						},
																					},
																				],
																				padding: {
																					_value: '0 !important',
																				},
																			},
																		},
																	},
																},
															},
															tooltip: {
																children: {
																	wrapper: {
																		display: 'inline-block',
																		margin: {
																			left: '6px',
																		},
																	},
																},
															},
														},
													},
												},
												inputConfiguration: {
													inputs: [
														{
															inputCode: 'culture',
															inputDataType: 'STRG',
															inputName: 'Culture',
															inputSource: {
																inputType: 'custom values',
																values: [],
															},
														},
														{
															inputCode: 'client_code',
															inputDataType: 'STRG',
															inputName: 'Client Code',
															inputSource: {
																inputType: 'custom values',
																values: [],
															},
														},
													],
													urlParamMappings: [],
												},
												authentication: {
													serviceUrl: 'https://api-eu.kurtosys.app',
													token: '617793dd-644b-4f9b-85aa-df2a6789b61a',
												},
												applicationClientConfigurationIds: [27, 33],
											},
										],
										translations: [],
									},
								},
							},
						},
					},
				},
			},
		} as IAppConfigurationBase,
		authentication: {
			token: '617793dd-644b-4f9b-85aa-df2a6789b61a',
		},
		parentId: 27,
		lastModified: '2023-04-19T01:09:27.000Z',
		lastModifiedBy: 2202,
		created: '2023-03-22T04:51:22.000Z',
		createdBy: 2202,
	},
];
