import {
	defineEventBusComponent,
	HYDRATION_KEY,
	loadExternalScripts,
	Manifest,
	TemplateStore,
} from '@kurtosys/app-loader';
import { IAppsHydration } from '@kurtosys/types/app/IAppsHydration.js';
import { ITemplateConfiguration } from '@kurtosys/types/app/ITemplateConfiguration.js';

export function getHydrationPoint(): IAppsHydration {
	if (!(window as any)[HYDRATION_KEY]) {
		(window as any)[HYDRATION_KEY] = {};
	}
	return (window as any)[HYDRATION_KEY];
}
const templateConfig = import('./configuration/templateConfig.json') as unknown as Promise<ITemplateConfiguration>;

export const TEMPLATE_STORE = new TemplateStore(templateConfig);

async function initialize() {
	await TEMPLATE_STORE.initialize();
	const manifest = new Manifest(TEMPLATE_STORE.templateConfiguration);

	defineEventBusComponent();
	await loadExternalScripts(
		manifest,
		() => {
			require('./start');
		},
		TEMPLATE_STORE.externalScriptsBasePath || manifest.getBaseUrl(''),
		TEMPLATE_STORE.cacheOptions,
	);
}

initialize();
