import { stores } from '@kurtosys/app-start';
import { requests } from '@kurtosys/ksys-api-client';
import { IRequestOptions } from '@kurtosys/types/api/IRequestOptions';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { computed, makeObservable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles';
import { IConfiguration } from '../../../models/app/IConfiguration';
import { TStoreContext } from '../../../models/app/TStoreContext';

import { AppStore } from './AppStore';
type TListApplications = requests.applicationManager.ListApplications;
type TInitialize = requests.apps.Initialize;

const KurtosysApiStoreBase = stores.KurtosysApiStore<IConfiguration, IComponentStyles>;
const ListApplications = requests.applicationManager.ListApplications;
const Initialize = requests.apps.Initialize;

export class KurtosysApiStore extends KurtosysApiStoreBase {
	appStore: AppStore;
	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);

		makeObservable(this);
	}

	@computed
	get listApplications(): TListApplications {
		return new ListApplications(this.wrappedToken, this.options);
	}

	@computed
	get initializeApps(): TInitialize {
		return new Initialize(this.wrappedToken, this.options);
	}

	get options(): IRequestOptions<any> {
		return {
			baseUrl: this.serviceUrl,
		};
	}

	get serviceUrl(): string {
		const appStore = this.storeContext.get<AppStore>('appStore');
		if (
			appStore.authentication &&
			appStore.authentication.serviceUrl &&
			appStore.authentication.serviceUrl.length > 0
		) {
			return appStore.authentication.serviceUrl;
		}
		if (appStore.configuration && appStore.configuration.core && appStore.configuration.core.serviceUrl) {
			return appStore.configuration.core.serviceUrl;
		}
		return this.getBaseAddress();
	}

	getBaseAddress(suffix = 'services/') {
		return this.manifest.getBaseUrl(suffix);
	}
}
