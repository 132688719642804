import { TemplateStore } from '@kurtosys/app-loader/dist/TemplateStore.js';
import { appStartUtils, models, stores } from '@kurtosys/app-start';
import { EventTypes } from '@kurtosys/event-manager/dist/models/EventTypes.js';
import { appsManager, common } from '@kurtosys/ksys-app-template';
import { IApplication } from '@kurtosys/types/api/applicationManager/IApplication';
import { IApplicationClientConfiguration } from '@kurtosys/types/api/applicationManager/IApplicationClientConfiguration';
import { IGetApplicationAppConfigResponseBody } from '@kurtosys/types/api/config/IGetApplicationAppConfigResponseBody';
import { IAppsRender } from '@kurtosys/types/appsInitialize/IAppsRender';
import { IAppsResponse } from '@kurtosys/types/appsInitialize/IAppsResponse';
import { IAppVersions } from '@kurtosys/types/appsManager/IAppVersions';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { TExternalScriptOptions } from '@kurtosys/types/utils/TExternalScript';
import { action, computed, makeObservable, observable } from 'mobx';

import { APPLICATION_CLIENT_CONFIGURATION_IDS } from '../../../configuration/development.applicationClientConfigurationIds.js';
import { APPLICATION_CLIENT_CONFIGURATIONS } from '../../../configuration/development.applicationClientConfigurations.js';
import { LIBRARY_COMPONENTS_CONFIGURATION } from '../../../configuration/libraryComponentsConfiguration.js';
import { HYDRATION_KEY } from '../../../constants.js';
import { TEMPLATE_STORE } from '../../../initialize.js';
import { IComponentStyles } from '../../../models/app/IComponentStyles';
import { IConfiguration } from '../../../models/app/IConfiguration';
import { IInputs } from '../../../models/app/IInputs';
import { IStyles } from '../../../models/app/IStyles';
import { TStoreContext } from '../../../models/app/TStoreContext';
import { IAddEmbedOptions } from '../../../models/IAddEmbedOptions';
import { IAppInitializedTemplate } from '../../../models/IAppInitializedTemplate';
import { IAppsRequest, IAppsRequestOptions } from '../../../models/IApps';
import { IGroupRenderOptions } from '../../../models/IGroupRenderOptions';
import { Feature } from '../../shared/Feature.js';

import { KurtosysApiStore } from './KurtosysApiStore.js';
type IAppComponents = models.components.app.IAppComponents;

/* [Component: appStoreComponentImport] */
const { AppParamsHelper, EventBusHelper } = common.helpers;
const { deepMergeObjects } = common.commonUtils;
type TEventBusHelper = common.helpers.EventBusHelper;
let devConfig: models.IAppDevelopmentConfig = {
	applicationClientConfigurationIds: null,
	applicationClientConfigurations: null,
	authentication: null,
	configuration: null,
	styles: null,
	libraryComponentsConfiguration: LIBRARY_COMPONENTS_CONFIGURATION,
};

if (process.env.NODE_ENV !== 'production') {
	devConfig = {
		...devConfig,
		applicationClientConfigurationIds: async () => {
			const { APPLICATION_CLIENT_CONFIGURATION_IDS } = await import(
				'../../../configuration/development.applicationClientConfigurationIds.js'
			);
			return APPLICATION_CLIENT_CONFIGURATION_IDS;
		},
		applicationClientConfigurations: async () => {
			const { APPLICATION_CLIENT_CONFIGURATIONS } = await import(
				'../../../configuration/development.applicationClientConfigurations.js'
			);
			return APPLICATION_CLIENT_CONFIGURATIONS;
		},
		authentication: async () => {
			const { AUTHENTICATION } = await import('../../../configuration/development.authentication.js');
			return AUTHENTICATION;
		},
		configuration: async () => {
			const { CONFIGURATION } = await import('../../../configuration/development.config.js');
			return CONFIGURATION;
		},
		styles: async () => {
			const { STYLES } = await import('../../../configuration/development.styles.js');
			return STYLES;
		},
	};
}

export class AppStore extends stores.base.AppStoreBase<IConfiguration, IComponentStyles> {
	// appParamsHelper: TAppParamsHelper;
	appHydration: appsManager.AppHydration;
	appsManager: appsManager.AppsManager;
	eventBusHelper: TEventBusHelper;

	// Group render appInitialized event tracking
	@observable.ref groupRenderOptions: IGroupRenderOptions[] = [];
	@observable.ref appInitializedTemplates: IAppInitializedTemplate[] = [];

	// App loading states
	url: URL;
	rawUrl: string;
	templateStore: TemplateStore;

	constructor(element: HTMLElement, url: string, storeContext: TStoreContext, manifest: IManifest) {
		super(element, url, storeContext, manifest, Feature, devConfig);
		this.templateStore = TEMPLATE_STORE;
		this.appParamsHelper = new AppParamsHelper(element, url);
		this.appHydration = new appsManager.AppHydration(HYDRATION_KEY);
		this.appsManager = new appsManager.AppsManager(
			this.manifest,
			this.appHydration,
			this.templateStore.appScriptsBasePath,
		);
		this.eventBusHelper = new EventBusHelper(this.applicationGuid);
		this.rawUrl = url;
		this.url = new URL(url);
		this.initializeAppManagerEvents();
		makeObservable(this);
	}

	get hasData(): boolean {
		return false;
	}
	@computed
	get components(): IAppComponents {
		return {
			/* [Component: appStoreComponent] */
		};
	}

	@computed
	get appVersions(): IAppVersions {
		return (this.configuration && this.configuration.appVersions) || {};
	}

	@computed
	get appsRequestOptions(): IAppsRequestOptions {
		return (this.configuration && this.configuration.appsRequestOptons) || {};
	}

	@computed
	get appsExternalScriptOptions(): TExternalScriptOptions {
		return (this.configuration && this.configuration.appsExternalScriptOptions) || {};
	}

	@computed
	get appsRenderOptions(): IAppsRender | undefined {
		return (this.configuration && this.configuration.appsRenderOptons) || undefined;
	}

	@computed
	get elements(): HTMLElement[] {
		return Array.from(document.querySelectorAll('[data-ksys-app-template-id]')) as any as HTMLElement[];
	}

	async initialize() {
		const promises: Promise<any>[] = [this.getAppConfig(), this.templateStore.initialize()];

		const [appConfigResponse, templateStoreResponse] = await Promise.all(promises);
		const kurtosysApiStore = this.storeContext.get<KurtosysApiStore>('kurtosysApiStore');
		if (appConfigResponse) {
			this.authentication = appConfigResponse.authentication;
			const token =
				(this.authentication && this.authentication?.token) ||
				(this.configuration && this.configuration?.token) ||
				'';
			if (kurtosysApiStore) {
				kurtosysApiStore.token = token;
			}

			this.applicationClientConfigurationIds = appConfigResponse.applicationClientConfigurationIds;
			this.rawConfiguration = await this.buildConfiguration(
				appConfigResponse.applicationConfiguration as any as IConfiguration,
			);
			this.rawStyles = appConfigResponse.applicationStyles as any as IStyles;

			this.docReady(async () => {
				this.renderSkeletonLoaders();

				this.setIsBootstrapped();
				const { groups = [], ...appsRenderOptions } = this.appsRenderOptions || {};

				// Gets the list of apps that exist on the page
				const elementDivs: string[] = [];
				this.elements.find((element) => {
					if (element && element.dataset && element.dataset.ksysAppTemplateId) {
						if (!elementDivs.includes(element.dataset.ksysAppTemplateId)) {
							elementDivs.push(element.dataset.ksysAppTemplateId);
						}
					}
				});

				if (groups && groups.length > 0) {
					for (const group of groups) {
						// Remove the app from the group that does not exist on the page
						const workingGroupApps = [];
						for (let i = 0; i < group.apps.length; i++) {
							const targetGroupApp = group.apps[i];
							if (elementDivs.includes(targetGroupApp.templateId)) {
								workingGroupApps.push(targetGroupApp);
							}
						}
						if (workingGroupApps.length > 0) {
							group.apps = workingGroupApps;
	
							const initOptions: IGroupRenderOptions = {
								group,
								appsRenderOptions: appsRenderOptions as IAppsRender,
								url: this.rawUrl,
								appVersions: this.appVersions,
								appsRequestOptions: this.appsRequestOptions,
								initializeAppRequests: this.initializeAppRequests,
								getApplications: this.getApplications,
								externalScriptOptions: this.appsExternalScriptOptions,
								assetRegisterCacheOptions: this.assetCacheOptions,
							};
	
							this.addGroupRenderOptions(initOptions);
						}
					}

					// Initialize the aboveFold apps first,
					// the belowFold apps will load once the aboveFold apps
					// have initialized.
					// See initializeAppManagerEvents function for the event setup
					this.initializeAppManager();
				}
			});
		}
	}

	async buildConfiguration(configuration: IConfiguration): Promise<IConfiguration> {
		let response: IConfiguration = {};
		const applicationClientConfigurationIds = this.applicationClientConfigurationIds || [];
		if (applicationClientConfigurationIds && applicationClientConfigurationIds.length > 0) {
			if (this.isDevelopment) {
				if (this.applicationClientConfigurations.length === 0) {
					this.applicationClientConfigurations = [...APPLICATION_CLIENT_CONFIGURATIONS];
				}
				const results = applicationClientConfigurationIds.map((id) => {
					const applicationClientConfiguration = this.applicationClientConfigurations.find(
						(a) => a.applicationClientConfigurationId === id,
					);
					return applicationClientConfiguration;
				});
				const errors = results.filter((result) => !result);
				if (errors.length === 0) {
					response = deepMergeObjects(
						response,
						...results.map((result) => (result && result.configuration) || {}),
					);
				} else {
					console.warn(
						`applicationClientConfigurationIds don't have all available application client configurations in the APPLICATION_CLIENT_CONFIGURATIONS collection`,
						{ APPLICATION_CLIENT_CONFIGURATIONS, APPLICATION_CLIENT_CONFIGURATION_IDS },
					);
				}
			} else {
				if (this.authentication && this.authentication.token) {
					const promises: Promise<IApplicationClientConfiguration>[] = [];
					const kurtosysApiStore = this.storeContext.get<KurtosysApiStore>('kurtosysApiStore');
					for (const applicationClientConfigurationId of applicationClientConfigurationIds) {
						const existingApplicationClientConfiguration = this.applicationClientConfigurations.find(
							(a) => a.applicationClientConfigurationId === applicationClientConfigurationId,
						);
						if (existingApplicationClientConfiguration) {
							promises.push(
								new Promise((resolve) => {
									resolve(existingApplicationClientConfiguration);
								}),
							);
						}
						const queryString: any = {
							applicationClientConfigurationId,
						};
						promises.push(
							kurtosysApiStore.getApplicationClientConfiguration.execute({
								queryString,
							}),
						);
					}
					const results = await Promise.all<IApplicationClientConfiguration>(
						promises.map((p) => p.catch((e) => e)),
					);
					const errors = results.filter((result) => result instanceof Error);
					if (errors.length === 0) {
						const resultsToAdd = results.filter((result) => {
							return !this.applicationClientConfigurations.some(
								(a) => a.applicationClientConfigurationId === result.applicationClientConfigurationId,
							);
						});
						if (resultsToAdd && resultsToAdd.length > 0) {
							this.applicationClientConfigurations = [
								...this.applicationClientConfigurations,
								...resultsToAdd,
							];
						}
						response = deepMergeObjects(response, ...results.map((result) => result.configuration));
					} else {
						console.warn({ buildConfigurationErrors: errors });
					}
				}
			}
		}
		if (configuration) {
			response = deepMergeObjects(response, configuration);
		}
		return response;
	}

	async initializeAppManager() {
		const optionsIdx = this.groupRenderOptions.findIndex((option) => !option.initialized);
		const options = this.groupRenderOptions[optionsIdx];
		if (options) {
			const { group } = options;
			if (group) {
				for (const template of group.apps) {
					this.updateAppInitializedTemplate(template);
				}
			}
			const initializeResponse = await this.appsManager.initialize(options);
			const { elements } = initializeResponse;
			this.triggerInitializeEventForElements(elements);
			this.updateGroupRenderOptions(optionsIdx, { ...options, initialized: true });
		}
	}

	initializeAppManagerEvents = () => {
		const appInitializedFn = async (event: any) => {
			const currentTemplate = this.appInitializedTemplates.find((template) => {
				let templateId = '';
				if (event && event.detail) {
					const { sourceApplication = {} } = event.detail;
					if (sourceApplication && sourceApplication.applicationId) {
						templateId = sourceApplication.applicationId;
					} else if (sourceApplication && sourceApplication.templateId) {
						templateId = sourceApplication.templateId;
					} else if (event.detail.templateId) {
						templateId = event.detail.templateId;
					}
				}
				return template.templateId === templateId;
			});
			if (currentTemplate) {
				this.updateAppInitializedTemplate({ ...currentTemplate, initialized: true });
			}
			// Initialize next group only once all templates for a group have been initialized
			if (this.appInitializedTemplates.every((template) => template.initialized)) {
				this.initializeAppManager();

				// Unregister from event bus only when all groups and templates have been initialized
				if (this.groupRenderOptions.every((option) => option.initialized)) {
					this.eventBusHelper.unregister(EventTypes.APP_INITIALIZED, appInitializedFn);
				}
			}
		};

		this.eventBusHelper.register(EventTypes.APP_INITIALIZED, appInitializedFn);
	};

	@action
	updateGroupRenderOptions = (index: number, options: IGroupRenderOptions) => {
		this.groupRenderOptions[index] = options;
	};

	@action
	addGroupRenderOptions = (options: IGroupRenderOptions) => {
		this.groupRenderOptions = [...this.groupRenderOptions, options];
	};

	@action
	updateAppInitializedTemplate = (template: IAppInitializedTemplate) => {
		const otherTemplates = this.appInitializedTemplates.filter(
			(initializedTemplate) => initializedTemplate.templateId !== template.templateId,
		);
		this.appInitializedTemplates = [...otherTemplates, template];
	};

	appEmbedAddedTimeout: NodeJS.Timeout | undefined;
	unprocessedAppEmbeds: Set<HTMLElement> = new Set();
	processedAppEmbeds: Set<HTMLElement> = new Set();

	@action
	async addEmbed(options: IAddEmbedOptions = {}) {
		const embedTarget = options?.embedTarget;
		if (embedTarget && !this.processedAppEmbeds.has(embedTarget) && !this.unprocessedAppEmbeds.has(embedTarget)) {
			if (this.appEmbedAddedTimeout) {
				clearTimeout(this.appEmbedAddedTimeout);
			}
			this.unprocessedAppEmbeds.add(embedTarget);
			this.processedAppEmbeds.add(embedTarget);
			this.appEmbedAddedTimeout = setTimeout(async () => {
				const { groups = [], ...appsRenderOptions } = this.appsRenderOptions || {};
				const unprocessedAppEmebedElements = Array.from(this.unprocessedAppEmbeds);
				this.processedAppEmbeds = new Set([...this.processedAppEmbeds, ...this.unprocessedAppEmbeds]);
				this.unprocessedAppEmbeds.clear();
				const initializeResponse = await this.appsManager.initialize({
					appsRenderOptions: appsRenderOptions as IAppsRender,
					url: this.rawUrl,
					appVersions: this.appVersions,
					appsRequestOptions: this.appsRequestOptions,
					initializeAppRequests: this.initializeAppRequests,
					getApplications: this.getApplications,
					externalScriptOptions: this.appsExternalScriptOptions,
					assetRegisterCacheOptions: this.assetCacheOptions,
					elements: unprocessedAppEmebedElements,
				});
				const { elements } = initializeResponse;
				this.triggerInitializeEventForElements(elements);
			}, 100);
		}
	}

	triggerInitializeEventForElements = (elements: HTMLElement[]) => {
		const eventName = appStartUtils.EVENTS.KSYS_APP_MANAGER_INITIALIZED;
		for (const element of elements) {
			appStartUtils.triggerEvent(eventName, element);
		}
	};

	docReady = (fn: any) => {
		if (fn && typeof fn === 'function') {
			// see if DOM is already available
			if (document.readyState === 'complete' || document.readyState === 'interactive') {
				// call on next available tick
				setTimeout(fn, 1);
			} else {
				document.addEventListener('DOMContentLoaded', fn);
			}
		}
	};

	renderSkeletonLoaders() {
		const skeletonLoadersConfig = this.coreConfigurations && this.coreConfigurations.skeletonLoaders;
		if (skeletonLoadersConfig) {
			const { loaders = [], variables } = skeletonLoadersConfig;
			for (const skeletonLoaderConfig of loaders) {
				if (skeletonLoaderConfig) {
					const { id, configurationKey = 'default' } = skeletonLoaderConfig;

					const targetElement = this.elements.find((element) => {
						if (element && element.dataset && element.dataset.ksysAppTemplateId === id) {
							const elementConfigurationKey = element.dataset.configurationKey;
							if (configurationKey === 'default' && !elementConfigurationKey) {
								return true;
							}
							if (elementConfigurationKey && elementConfigurationKey === configurationKey) {
								return true;
							}
						}
						return false;
					});
					if (targetElement) {
						const skeletonLoader = new appsManager.SkeletonLoader(skeletonLoaderConfig, variables);
						skeletonLoader.render(targetElement);
					}
				}
			}
		}
	}

	initializeAppRequests = async (appsRequests: IAppsRequest[]): Promise<IAppsResponse[]> => {
		const kurtosysApiStore = this.storeContext.get<KurtosysApiStore>('kurtosysApiStore');
		const promises: Promise<any>[] = appsRequests.map((appsRequest) =>
			kurtosysApiStore.initializeApps
				.execute({
					method: 'GET',
					queryString: { request: JSON.stringify(appsRequest) },
				})
				.catch((e) => e),
		);
		const appsResponses = await Promise.all(promises);
		return appsResponses;
	};

	getApplications = async (): Promise<IApplication[]> => {
		try {
			const kurtosysApiStore = this.storeContext.get<KurtosysApiStore>('kurtosysApiStore');
			const applicationsResponse = await kurtosysApiStore.listApplications.execute({
				body: {},
			});
			const { data: applications } = applicationsResponse;
			return applications;
		} catch (ex) {
			console.warn(`Exception in getApplications: `, ex);
		}
		return [];
	};

	// async getAppConfig(): Promise<IGetApplicationAppConfigResponseBody> {
	// 	const appFromHydration = this.appHydration.getAppHydration(this.manifest, this.appParamsHelper);
	// 	if (appFromHydration) {
	// 		const { applicationConfiguration, applicationStyles, authentication, applicationClientConfigurationIds } = appFromHydration;
	// 		return {
	// 			applicationConfiguration,
	// 			applicationStyles,
	// 			authentication,
	// 			applicationClientConfigurationIds,
	// 		};
	// 	}
	// 	// We want to use the hard coded configuration when developing locally
	// 	// otherwise fetch it
	// 	let response: IGetApplicationAppConfigResponseBody = {
	// 		applicationConfiguration: CONFIGURATION,
	// 		applicationStyles: STYLES,
	// 		authentication: AUTHENTICATION,
	// 		applicationClientConfigurationIds: APPLICATION_CLIENT_CONFIGURATION_IDS,
	// 	};
	// 	if (!this.isDevelopment) {
	// 		response = await this.getAppConfigFromApi();
	// 	}
	// 	return response;
	// }

	async getAppConfigFromApi(): Promise<IGetApplicationAppConfigResponseBody> {
		const kurtosysApiStore = this.storeContext.get<KurtosysApiStore>('kurtosysApiStore');
		const configurationKey =
			(this.appParamsHelper.rawAppParams && this.appParamsHelper.rawAppParams.configurationKey) || 'default';
		return await kurtosysApiStore.getAppConfig.execute({
			urlParams: [configurationKey],
		});
	}

	getInput(inputKey: keyof IInputs) {
		return this.appParamsHelper.inputs && this.appParamsHelper.inputs[inputKey];
	}
}
