import { Manifest } from '@kurtosys/app-loader/dist/Manifest.js';
import { appsManager } from '@kurtosys/ksys-app-template';

import { AppStore } from './components/App/stores/AppStore.js';
import { IAddEmbedOptions } from './models/IAddEmbedOptions.js';
import { getStoreContextForElement } from './utils/store.js';
import { EVENTS } from './constants.js';
import { TEMPLATE_STORE } from './initialize.js';
const manifest = new Manifest(TEMPLATE_STORE.templateConfiguration);
const URL = window.location.href;
(window as any).ksysAddEmbeddedApps = addEmbeddedApps;

function initialize() {
	const elements = appsManager.utils.getElementsForManifest(manifest);
	for (const element of elements) {
		getStoreContextForElement(element, URL, manifest);
	}
}

export function addEmbeddedApps(addEmbedOptions?: IAddEmbedOptions) {
	const managerElement = document.querySelector('[data-ksys-app-template-id="ksys-app-manager"]');
	if (managerElement) {
		const event = new CustomEvent(EVENTS.KSYS_APP_MANAGER_EMBED_ADDED, {
			bubbles: true,
			cancelable: true,
			detail: {
				addEmbedOptions,
			},
		});
		managerElement.dispatchEvent(event);
	}
}

function embedAddedHandler(event: Event) {
	const customEvent = event as CustomEvent;
	const addEmbedOptions: IAddEmbedOptions = customEvent && customEvent.detail && customEvent.detail.addEmbedOptions;
	const elements = appsManager.utils.getElementsForManifest(manifest);
	for (const element of elements) {
		const storeContext = getStoreContextForElement(element, URL, manifest);
		const appStore = storeContext.get<AppStore>('appStore');
		appStore.addEmbed(addEmbedOptions);
	}
}

export function addEventListeners() {
	removeEventListeners();
	document.addEventListener(EVENTS.KSYS_APP_MANAGER_EMBED_ADDED, embedAddedHandler);
}

export function removeEventListeners() {
	document.removeEventListener(EVENTS.KSYS_APP_MANAGER_EMBED_ADDED, embedAddedHandler);
}

initialize();
addEventListeners();
